<template>
  <div>
    <v-tabs grow color="success darken-2" v-model="tab">
      <v-tab key="tabControl">
        <v-icon left>mdi-chart-timeline</v-icon>
        <h1>Control</h1></v-tab
      >

      <v-tab key="tabHistory">
        <v-icon left>mdi-file-document-multiple-outline</v-icon>
        <h1>Historial</h1>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="tabControl">
        <v-card flat>
          <v-card-text><s-control /></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="tabHistory">
        <v-card flat>
          <v-card-text><s-history></s-history></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import _sVehicle from "@/services/Management/VehicleService";
import sControl from "@/views/Management/ProgrammingVehicle/ProgrammingControl";
import sHistory from "@/views/Management/ProgrammingVehicle/ProgrammingHistory";

export default {
  components: { sControl, sHistory },
  data() {
    return {
      tab: "tabControl",
    };
  },
};
</script>
