<template>
  <div>
    <s-crud
      title="Salidas Vehiculares"
      ref="gridVehicle"
      add-no-function
      remove
      no-search
      :config="config"
      :filter="config.filter"
      @rowSelected="rowSelected($event)"
      @edit="edit()"
      @save="saveVeh($event)"
      excel
    >
      <template v-slot:filter>
        <v-container>
          <v-row>
            <v-col col="4" offset-md="2" md="4" offset-lg="3" lg="2">
              <s-select-vehicle label="Vehículo" v-model="config.filter.VehID" clearable />
            </v-col>

            <v-col col="4" md="2" lg="2"><s-date label="Inicio" v-model="config.filter.PrgTravelInitDate" date/></v-col>
            <v-col col="4" md="2" lg="2"><s-date label="Fin" v-model="config.filter.PrgTravelEndDate" date/></v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:PrgStatusText="{ row }">
        <v-chip dark x-small :color="colorStatus(row)" style="font-size:12px;">
          {{ row.PrgStatusText }}
        </v-chip>
      </template>
    </s-crud>
    <v-dialog max-width="750" v-model="dialog" persistent>
      <s-programming-edit @save="save()" @close="dialog = false" v-model="programming.PrgID" :veh="programming.VehID" />
    </v-dialog>
  </div>
</template>

<script>
import _sProgramming from "@/services/Management/ProgrammingVehicleService";
import sProgrammingEdit from "@/views/Management/ProgrammingVehicle/ProgrammingVehicleEdit";
import SSelectVehicle from "@/components/Utils/Vehicle/SSelectVehicle.vue";
export default {
  components: { sProgrammingEdit, SSelectVehicle },
  data() {
    return {
      config: {
        service: _sProgramming,
        filter: { VehID: null, PrgTravelInitDate: null, PrgTravelEndDate: null },
        model: {
          VehID: "int",
          TypeVehicleText: "string",
          VehPlate: "string",
          PrgID: "ID",
          PrgDate: "date",
          CCeName: "string",
          PrgMileageInit: "decimal",
          PrgTravelInitHour: "datetime",
          PrgTravelEndHour: "datetime",
          NtpDriverName: "string",
          PrgStatus: "int",
          PrgStatusText: "string",
        },
        headers: [
          {
            text: "Fecha",
            value: "PrgDate",
            width: "95",
          },
          {
            text: "Tipo",
            value: "TypeVehicleText",
            width: "10%",
          },
          {
            text: "Placa",
            value: "VehPlate",
            width: "83",
          },
          {
            text: "Conductor",
            value: "NtpDriverName",
            width: "250",
          },
          {
            text: "Salida",
            value: "PrgTravelInitHour",
            width: "120",
          },
          {
            text: "Retorno",
            value: "PrgTravelEndHour",
            width: "120",
          },
          {
            text: "Estado",
            value: "PrgStatusText",
            width: "5%",
            align: "center",
          },
        ],
      },
      selected: {},
      dialog: false,
      programming: { PrgID: 0, VehID: 0 },
    };
  },
  methods: {
    rowSelected(item) {
      this.selected = item;
    },
    add() {
      this.dialog = true;
    },
    edit() {
      this.programming = this.selected.length > 0 ? this.selected[0] : {};
      this.dialog = true;
    },
    save() {
      this.$refs.gridVehicle.refresh();
    },
    colorStatus(item) {
      let color = "";

      if (item.PrgStatus != "" && item.PrgStatus != null && item.PrgStatus > 0) {
        if (item.PrgStatus == 1) color = "primary";
        else if (item.PrgStatus == 2) color = "orange";
        else if (item.PrgStatus == 3) color = "success darken-2";
        else color = "gray";
      }

      return color;
    },
  },
  computed: {},
};
</script>
