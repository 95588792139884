import Service from "../Service";

const resource = "programmingvehicle/";

export default {
  pagination(dtr, requestID) {
    return Service.post(resource + "control", dtr, {
      params: { requestID: requestID },
    });
  },
};
