import Service from "../Service";

const resource = "programmingvehicle/";

export default {
  save(veh, requestID) {
    return Service.post(resource + "save", veh, {
      params: { requestID: requestID },
    });
  },
  pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },
  excel(dtr, requestID) {
    return Service.post(resource + "excel", dtr, {
      params: { requestID: requestID },
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get(veh, requestID) {
    return Service.post(resource, veh, {
      params: { requestID: requestID },
    });
  },
};
