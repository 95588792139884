<template>
  <div>
    <s-crud
      title="Vehículos"
      ref="gridVehicle"
      edit
      add-no-function
      remove
      noSearch
      noFooter
      :config="config"
      @rowSelected="rowSelected($event)"
      @edit="edit()"
      @save="saveVeh($event)"
    >
      <template v-slot:PrgStatusText="{ row }">
        <v-chip v-if="row.PrgStatus > 0" dark x-small :color="colorStatus(row)" style="font-size: 12px">
          {{ row.PrgStatusText }}
        </v-chip>
      </template>
    </s-crud>
    <v-dialog max-width="750" v-model="dialog" persistent>
      <s-programming-edit @save="save()" @close="dialog = false" v-model="programming.PrgID" :veh="programming.VehID" />
    </v-dialog>
  </div>
</template>

<script>
import _sProgrammingControl from "@/services/Management/ProgrammingVehicleControlService";
import sProgrammingEdit from "@/views/Management/ProgrammingVehicle/ProgrammingVehicleEdit";
export default {
  components: { sProgrammingEdit },
  data() {
    return {
      date: null,
      config: {
        service: _sProgrammingControl,
        model: {
          VehID: "ID",
          TypeVehicleText: "string",
          VehPlate: "string",
          PrgID: "int",
          PrgDate: "date",
          CCeName: "string",
          PrgMileageInit: "decimal",
          PrgTravelInitHour: "datetime",
          NtpDriverName: "string",
          PrgStatus: "int",
          PrgStatusText: "string",
          SecStatus: "status",
        },
        headers: [
          {
            text: "Estado",
            value: "PrgStatusText",
            width: "5%",
            align: "center",
          },
          {
            text: "Tipo",
            value: "TypeVehicleText",
            width: "10%",
          },
          {
            text: "Placa",
            value: "VehPlate",
            width: "83",
          },

          {
            text: "Fecha",
            value: "PrgDate",
            width: "95",
          },
          {
            text: "Salida",
            value: "PrgTravelInitHour",
            width: "120",
          },
          {
            text: "Conductor",
            value: "NtpDriverName",
            width: "120",
          },

          {
            text: "KM",
            value: "PrgMileageInit",
            align: "end",
            width: "5%",
          },
        ],
      },
      selected: {},
      dialog: false,
      programming: { PrgID: 0, VehID: 0 },
    };
  },
  methods: {
    rowSelected(item) {
      this.selected = item;
    },
    add() {
      this.dialog = true;
    },
    edit() {
      this.programming = this.selected.length > 0 ? this.selected[0] : {};
      this.dialog = true;
    },
    save() {
      this.programming = {};
      this.$refs.gridVehicle.refresh();
    },
    colorStatus(item) {
      let color = "";

      if (item.PrgStatus != "" && item.PrgStatus != null && item.PrgStatus > 0) {
        if (item.PrgStatus == 1) color = "primary";
        else if (item.PrgStatus == 2) color = "orange";
        else color = "gray";
      }

      return color;
    },
  },
  computed: {},
};
</script>
