<template>
  <div>
    <s-toolbar @close="close()" close label="Programación de Vehículo" />

    <v-card elevation="0" tile>
      <v-card-title class="primary--text py-3">Datos Generales</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="s-col-form py-0">
            <s-switch v-if="item.PrgStatus == 1 && isAdmin" label="ES PROGRAMACIÓN DE VEHÍCULO" v-model="isProgramming" />
            <s-switch v-if="item.PrgStatus == 2" label="RETORNO DE VEHÍCULO" v-model="isCloseTravel" color="success darken-2" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" lg="2" class="s-col-form">
            <s-date label="Fecha" v-model="item.PrgDate" :readonly="item.PrgStatus != 1" />
          </v-col>
          <v-col cols="4" lg="3" class="s-col-form">
            <s-select-vehicle v-if="item.PrgID == 0" label="Vehículo" v-model="item.VehID" :toProgramming="true" @input="inputVehicle" add />
            <s-text v-show="!(item.PrgID == 0)" label="Vehículo" v-model="item.VehPlate" readonly />
          </v-col>
          <v-col v-show="item.PrgID > 0" cols="4" lg="4" class="s-col-form">
            <v-chip dark small :color="colorStatus(item)" class="mt-4"> Estado: {{ item.PrgStatusText }} </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="s-col-form">
            <s-select-area
              v-show="item.PrgStatus == 1 || (item.PrgStatus == 2 && isCloseTravel == 0)"
              label="Centro Costo"
              centercost
              v-model="item.CceID"
              :text="item.CceName"
            />

            <s-text
              v-show="!(item.PrgStatus == 1 || (item.PrgStatus == 2 && isCloseTravel == 0))"
              label="Centro Costo"
              v-model="item.CceName"
              readonly
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title class="primary--text py-3">Datos del Conductor</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6" sm="3" lg="3" class="s-col-form">
            <s-toolbar-person
              v-show="item.PrgStatus < 3"
              label="Documento"
              addDriver
              v-model="item.PrsDocumentNumber"
              @returnPerson="prgReturnPerson($event)"
              :autofocus="false"
            />
            <s-text v-show="item.PrgStatus == 3" readonly label="Documento" v-model="item.PrsDocumentNumber" />
          </v-col>
          <v-col cols="6" sm="3" lg="3" class="s-col-form">
            <s-text readonly label="Brevete" v-model="item.NpdDocumentNumber" />
          </v-col>
          <v-col cols="12" sm="6" lg="6" class="s-col-form">
            <s-text readonly label="Conductor" v-model="item.NtpDriverName" />
          </v-col>
        </v-row>

        <v-row v-if="item.NtpDriverID > 0 && errorMessageDriver.length > 0">
          <v-col cols="12" class="s-col-form">
            <v-label>
              <div class="red--text">{{ errorMessageDriver }}</div>
            </v-label>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pa-0" v-show="!isProgramming">
        <v-row>
          <v-col cols="12" sm="6" lg="6">
            <v-card elevation="0" class="px-0 ma-0" tile>
              <v-card-title class="primary--text pt-2">Salida de Vehículo</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="8" lg="6" class="s-col-form">
                    <s-date
                      label="Fecha y Hora"
                      nullable
                      v-model="item.PrgTravelInitHour"
                      type="datetime"
                      :readonly="(item.PrgStatus == 1 && isProgramming == 1) || (item.PrgStatus == 2 && isCloseTravel == 1)"
                    />
                  </v-col>

                  <v-col cols="4" lg="4" class="s-col-form">
                    <s-text
                      label="Kilometraje"
                      v-model="item.PrgMileageInit"
                      decimal
                      :readonly="(item.PrgStatus == 1 && isProgramming == 1) || (item.PrgStatus == 2 && isCloseTravel == 1)"
                      autofocus
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="6">
            <v-card elevation="0" class="pa-0 ma-0" tile>
              <v-card-title class="primary--text pt-2">Retorno de Vehículo</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="8" lg="6" class="s-col-form">
                    <s-date
                      label="Fecha y Hora"
                      nullable
                      v-model="item.PrgTravelEndHour"
                      type="datetime"
                      :readonly="!(item.PrgStatus == 2 && isCloseTravel == 1)"
                    />
                  </v-col>

                  <v-col cols="4" lg="4" class="s-col-form">
                    <s-text
                      label="Kilometraje"
                      v-model="item.PrgMileageEnd"
                      decimal
                      :readonly="!(item.PrgStatus == 2 && isCloseTravel == 1)"
                      autofocus
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="py-0">
            <s-text label="Observaciones" v-model="item.PrgObservation" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col cols="12" lg="6" offset-lg="3">
              <v-btn @click="savePrg(1)" block color="primary darken-1" v-if="item.PrgStatus == 1 && isProgramming == 1"> Programar </v-btn>
              <v-btn
                @click="savePrg(2)"
                block
                dark
                color="orange"
                v-if="(item.PrgStatus == 1 && isProgramming == 0) || (item.PrgStatus == 2 && isCloseTravel == 0)"
                >Vehículo en Ruta
              </v-btn>
              <v-btn @click="savePrg(3)" v-if="item.PrgStatus == 2 && item.PrgID > 0 && isCloseTravel == 1" block color="success darken-2"
                >Finalizar Ruta
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _sProgrammingVehicle from "@/services/Management/ProgrammingVehicleService";
import _sVehicle from "@/services/Management/VehicleService";
import _sPerson from "@/services/General/PersonService";
import SSelectArea from "@/components/Utils/SSelectArea.vue";
import SSelectVehicle from "@/components/Utils/Vehicle/SSelectVehicle.vue";
import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson.vue";

export default {
  components: { SSelectArea, SSelectVehicle, SToolbarPerson },
  props: {
    value: 0,
    veh: 0,
  },
  data() {
    return {
      item: {
        PrgID: 0,
        VehID: 0,
        PrsID: 0,
        NtpDriverID: 0,
        NtpDriverName: "",
        PrgDate: "",
        VehPlate: "",
        PrgTravelInitDate: null,
        PrgTravelInitHour: null,
        PrgMileageInit: null,
        PrgTravelEndDate: null,
        PrgTravelEndHour: null,
        PrgMileageEnd: null,
        PrsDocumentNumber: "",
        NpdDocumentEnd: "",
        NpdDocumentNumber: "",
        PrgStatus: 1,
        SecStatus: 1,
      },
      isAdmin: false,
      isProgramming: 0,
      isCloseTravel: 1,
      verificationDay: "",
    };
  },
  mounted() {
    this.verificationDay = this.moment().add(15, "days").format(this.$const.FormatDateMoment);
    this.getProgramming(this.value);
    this.isAdmin = this.$fun.isAdmin();
  },
  watch: {
    veh(val) {
      console.log(this.value);
      if (!this.value > 0) {
        this.item.VehID = this.veh;
        this.isProgramming = 0;
      }
    },
    value(val) {
      this.getProgramming(val);
    },
    isProgramming(val) {
      if (val == 0 && this.item.PrgStatus == 1) {
        this.item.PrgTravelInitHour = this.moment().format(this.$const.FormatDateTimeDB);
        _sVehicle.get({ VehID: this.item.VehID }, this.$fun.getUserID()).then((r) => {
          this.item.PrgMileageInit = r.data.VehMileage;
        });
      }
      if (val == 1) {
        this.item.PrgMileageInit = null;
        this.item.PrgTravelInitHour = null;
      }
    },
    isCloseTravel(val) {
      this.item.PrgMileageEnd = null;

      if (this.isCloseTravel == 1) {
        this.item.PrgTravelEndHour = this.moment().format(this.$const.FormatDateTimeDB);
      } else this.item.PrgTravelEndHour = null;
    },
  },
  computed: {
    errorMessageDriver() {
      let valid;

      valid = this.item.NpdDocumentNumber != null && this.item.NpdDocumentNumber != "";

      if (!valid) return "El conductor no tiene Brevete registrado.";
      else {
        valid = this.verificationDay < this.item.NpdDocumentEnd;
        if (!valid) return "Fecha de vencimiento de Brevete " + this.moment(this.item.NpdDocumentEnd).format(this.$const.FormatDateFunction);
      }

      return "";
    },
  },
  methods: {
    getProgramming(value) {
      this.isProgramming = 0;

      if (value > 0) {
        _sProgrammingVehicle.get({ PrgID: value }, this.$fun.getUserID()).then((r) => {
          this.item = r.data;

          if (this.item.PrgStatus == 1 && this.isProgramming == 0) this.inputVehicle();

          if (this.item.PrgStatus == 2) {
            this.isCloseTravel = 1;
            this.item.PrgTravelEndHour = this.moment().format(this.$const.FormatDateTimeDB);
          } else {
            this.isCloseTravel = 0;
            this.item.PrgTravelEndHour = null;
          }
          this.returnObject();
        });
      } else {
        this.isCloseTravel = 0;
        this.item = this.defaultItem();
        this.item.VehID = this.veh;
      }
    },
    defaultItem() {
      let day = this.moment().format(this.$const.FormatDateFunction);

      return {
        PrgID: 0,
        VehID: 0,
        NtpDriverID: 0,
        NtpDriverName: "",
        PrgDate: day,
        VehPlate: "",
        PrgTravelInitDate: null,
        PrgTravelInitHour: null,
        PrgMileageInit: null,
        PrgTravelEndDate: null,
        PrgTravelEndHour: null,
        PrgMileageEnd: null,
        PrsDocumentNumber: "",
        NpdDocumentEnd: "",
        NpdDocumentNumber: "",
        PrgStatus: 1,
        SecStatus: 1,
      };
    },
    colorStatus(item) {
      let color = "";

      if (item.PrgStatus != "" && item.PrgStatus != null && item.PrgStatus > 0) {
        if (item.PrgStatus == 1) color = "primary darken-1";
        else if (item.PrgStatus == 2) color = "orange";
        else if (item.PrgStatus == 3) color = "success darken-2";
        else color = "gray";
      }

      return color;
    },
    returnObject() {
      this.$emit("returnObject", this.item);
    },
    inputVehicle() {
      if (this.item.VehID > 0 && this.isProgramming == 0) {
        this.item.PrgTravelInitHour = this.moment().format(this.$const.FormatDateTimeView);
        _sVehicle.get({ VehID: this.item.VehID }, this.$fun.getUserID()).then((r) => {
          this.item.PrgMileageInit = r.data.VehMileage;
        });
      } else {
        this.item.PrgMileageInit = null;
        this.item.PrgTravelInitHour = null;
      }
    },
    prgReturnPerson(value) {
      if (value != null) {
        this.item.NtpDriverName = value.NtpPaternalSurname + " " + value.NtpMaternalSurname + " " + value.NtpName;
        this.item.NtpDriverID = value.NtpID;

        if (this.item.PrgStatus != 3) {
          _sPerson.getNaturalPersonDriver(value, this.$fun.getUserID()).then((r) => {
            if (r.data != null) {
              this.item.NpdDocumentNumber = r.data.NpdDocumentNumber;
              this.item.NpdDocumentEnd = r.data.NpdDocumentEnd;
            } else {
              this.item.NpdDocumentNumber = "";
              this.item.NpdDocumentEnd = null;
            }
          });
        }
      } else {
        this.item.NtpDriverName = "";
        this.item.NpdDocumentNumber = "";
        this.item.NpdDocumentEnd = null;
        this.item.NtpDriverID = 0;
      }
    },
    close() {
      this.$emit("close");
    },
    validatePrg(item, toStatus) {
      let isValid = false;
      let message = "";
      isValid = item.VehID > 0;
      if (!isValid) message = "Seleccione Placa de vehículo";
      else {
        isValid = this.$fun.isValidDate(item.PrgDate);
        if (!isValid) message = "Ingrese Fecha de Programación";
        else {
          isValid = item.CceID > 0;
          if (!isValid) message = "Digite Centro de Costo";
          else {
            //En Ruta
            if (toStatus == 2) {
              isValid = item.NtpDriverID > 0;
              if (!isValid) message = "Ingrese conductor";
              else {
                isValid = this.$fun.isValidDateTime(item.PrgTravelInitHour);
                if (!isValid) message = "Ingrese Fecha y Hora de Salida de Vehículo";
                else {
                  isValid = item.PrgMileageInit > 0;
                  if (!isValid) message = "Registre Kilometraje de Salida";
                }
              }
            }
            //Cerrado
            if (toStatus == 3) {
              isValid = this.$fun.isValidDateTime(item.PrgTravelEndHour);
              if (!isValid) message = "Ingrese Fecha y Hora de Retorno de Vehículo";
              else {
                isValid = item.PrgMileageEnd >= item.PrgMileageInit;
                if (!isValid) message = "Registre Kilometraje de Retorno Mayor al Inicial";
              }
            }
          }
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");
      return isValid;
    },
    savePrg(prgStatus) {
      if (this.validatePrg(this.item, prgStatus)) {
        this.item.PrgStatus = prgStatus;
        _sProgrammingVehicle.save(this.item, this.$fun.getUserID()).then((r) => {
          this.$fun.alert("Guardado Correctamente", "success");

          this.$emit("save", r.data);
          this.close();
        });
      }
    },
  },
};
</script>
